import apiClient from '@/utils/api-client'

class RecaptchaService {
    public validateRecaptcha(responseToken: string) {
        return new Promise<boolean>((resolve, reject) => {
            apiClient.get('/api/v1/recaptcha/validate/web/' + responseToken).then((res) => {
                if (res.status == 200 && res.data != null) {
                    resolve(res.data)
                } else {
                    resolve(false)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default new RecaptchaService()

<template>
  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" @click="this.$router.push('/')">
        <img class="img-fluid" src="../assets/logo.svg" alt="">
      </a>
      <div class="d-flex">
        <a class="align-self-center" role="button" @click="doAccount">
          <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          My Account
        </a>
      </div>
    </div>
  </nav>

  <div class="main-height">
    <section class="contact-support">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12 mb-3">
              <h2 class="fw-bold">
                Contact Support
              </h2>
            </div>
          </div>
          
          <div class="row">
            <div class="col-12 mt-2">
              <div>
                <div class="form-group">
                  <textarea class="form-control" v-model="message" rows="5" id="comment" placeholder="Enter Your Query Here"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div v-if="notification.length" class="alert-success mt-3 text-success">
           
            {{ notification }}
          </div>
          
          <div v-if="error.length" class="alert-danger mt-3 text-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            {{ error }}
          </div>
          <div class="row">
            <div class="col-12 mt-4">
              <VueRecaptcha
                  :sitekey="this.siteKey"
                  :load-recaptcha-script="true"
                  @verify="handleSuccess"
                  @error="handleError"
              >
                <button class="btn btn-primary w-100 rounded-1">
                <span v-show="!loading">Submit</span>
                <div v-show="loading" class="button-loading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
                </button>
              </VueRecaptcha>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import UsersService from "@/services/users-service";
import Footer from "@/components/Footer.vue";
import {ContactFormRequest} from "@/model/models";
import AuthService from "@/services/auth-service";
import RecaptchaService from "@/services/recaptcha-service";
import { VueRecaptcha } from 'vue-recaptcha';

export default defineComponent({
  name: "Contact Support",
  components :{
    VueRecaptcha,
    Footer
  },  
  data() {
    return {
      message: '',
      error: '',
      notification: '',
      userProfile: null,
      siteKey: process.env.VUE_APP_RECAPTCHA_API_KEY,
      loading: false
    }
  },
  beforeMount() {
    // prevent direct navigation bugs
    if (localStorage.getItem('userProfile') == null) {
      AuthService.signOut().then(() => {
        this.$router.push('/signin')
      })
    }
    if (localStorage.getItem('userProfile')) this.userProfile = JSON.parse(localStorage.getItem('userProfile'))
  },
  methods: {
    handleSuccess: function(response) {
      // Do some validation
      RecaptchaService.validateRecaptcha(response).then((valid) => {
        if (valid) {
          this.doSubmit()
        } else {
          this.error = "Recaptcha validation failed"
        }
      })
    },
    handleError: function() {
      this.error="Recaptcha validation failed "
    },
    doAccount() {
      this.$router.push('/account')
    },
    doSubmit: function () {
      if (this.loading) return;
      this.loading = true;
      if (this.message != null && this.message != '') {
        const userInfo = navigator.userAgent
        UsersService.contactFormRequest(new ContactFormRequest(this.userProfile.email, this.message, userInfo)).then((valid) => {
          if (valid) {
            this.error = ''
            this.notification = "Your query has been successfully submitted. You will be contacted shortly.";
            this.loading = false;
          } else {
            this.error = "There was an error while submitting your query. Try again later.";
            this.loading = false;
          }
        })
      } else {
        this.error = "Please write a message.";
        this.loading = false;
      }
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>